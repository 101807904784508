<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'detail'">订单查看</BreadcrumbItem>
        <BreadcrumbItem v-if="type === 'examine'">订单审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ orderInfo.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="换货机构">
              {{ orderInfo.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ orderInfo.purchaserCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ orderInfo.producerCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ orderInfo.createTime ? tsFormat(orderInfo.createTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态">
              <span
                v-if="fromRouteName === 'ChangeOrderListFactory'"
                :class="
                  orderInfo.orderStatusValue === '待审核'
                    ? 'status--orange'
                    : ''
                "
              >
                {{ orderInfo.nodeStatus }}
              </span>
              <span v-else>
                {{ orderInfo.orderStatusValue }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(orderInfo.orderPurchaseSn)">
                  {{ orderInfo.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商家收发货信息</div>
      <div class="address__container" v-if="type === 'examine'">
        <span>{{ senderAddress }}</span>
        <a-icon
          type="edit"
          :style="{ fontSize: '16px' }"
          @click="onEditMineDeliverInfo"
        />
      </div>
      <a-form class="advanced-search-form" v-if="type === 'detail'">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收发货人姓名">
              {{ orderInfo.proName || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货人电话">
              {{ orderInfo.proTel || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货地址">
              {{ orderInfo.proAddress || "-" }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">客户收发货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收发货人姓名">
              {{ orderInfo.purName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货人电话">
              {{ orderInfo.purTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货地址">
              {{ orderInfo.purAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="goodsSn" slot-scope="text, record">
          <span v-if="record.skuCode === '合计：'"></span>
          <span v-else>{{ text ? text : "-" }}</span>
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">申请信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="原因描述">
              {{ orderInfo.orderMemo }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="照片">
              <div class="pic__container" v-viewer>
                <div v-for="(item, index) in orderInfo.files" :key="index">
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    :alt="item.fileName"
                  />
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section
      class="card__container"
      :class="type === 'examine' ? 'high__bottom' : 'low__bottom'"
    >
      <div class="container__title">流程日志</div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'examine'">
      <a-form class="examine__form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="审核意见">
              <a-textarea
                :auto-size="{ minRows: 1, maxRows: 2 }"
                v-model="approvalMemo"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="tip">
        <a-icon type="info-circle" />
        审核不通过，审核意见必填
      </div>
      <div class="operation">
        <a-space>
          <a-button @click="onReject">不通过</a-button>
          <a-button type="primary" @click="onPass" :loading="passLoading">
            通过
          </a-button>
        </a-space>
      </div>
    </section>

    <!-- 发货地址选择弹窗 -->
    <a-modal
      title="请选择合适的地址"
      :visible="sendVisible"
      @cancel="sendVisible = false"
    >
      <div v-if="addressList.length > 0">
        <a-radio-group v-model="defaultSelectAddressId">
          <a-radio
            v-for="item in addressList"
            :key="item.addressId"
            :value="item.addressId"
            class="radio__item"
          >
            {{ item.addressItem }}
          </a-radio>
        </a-radio-group>
      </div>
      <a-empty v-else description="暂无地址，请去新增" />
      <template slot="footer">
        <a-button @click="onEnterAddress">
          地址库管理
        </a-button>
        <a-button type="primary" @click="onSureAddress">
          确定
        </a-button>
      </template>
    </a-modal>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { fetchAddressList } from "@/services/OrderService";
import {
  fetchOrderDetail,
  fetchOrderLog,
  orderExamine
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";

const commodityColumns = [
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" },
    width: "15%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "换货数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "15%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];

export default {
  name: "ChangeOrderDetailFactory",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      senderAddress: "",
      defaultSelectAddressId: "",
      addressList: [],
      sendVisible: false,
      commodityData: [],
      commodityColumns,
      loading: false,
      logColumns,
      logData: [],
      logLoading: false,
      approvalMemo: "",
      orderInfo: {},
      tsFormat,
      imageBaseUrl,
      passLoading: false,
      fromRouteName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouteName = from.name;
    });
  },
  mounted() {
    this.onLoadAddressList();
    this.loadDetail();
    this.loadLogList();
  },
  methods: {
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "approval"
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.orderInfo = resp.data.data;
          this.commodityData = this.orderInfo.instantOrderSkus;
        }
      });
    },
    // 发货弹窗展示
    onEditMineDeliverInfo() {
      this.sendVisible = true;
    },
    // 获取当前原厂的发货地址列表
    onLoadAddressList() {
      const factoryId = localStorage.getItem("factoryId");
      fetchAddressList(factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.length > 0) {
            data.forEach(item => {
              if (
                item.senderProvinceName ||
                item.senderCityName ||
                item.senderCountyName
              )
                if (item.senderProvinceName === item.senderCityName) {
                  item.area = `${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                } else {
                  item.area = `${item.senderProvinceName} ${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                }
              item.addressItem = `${item.area}，${item.senderName}，${item.senderTel}`;
            });
          }
          this.addressList = data;
          if (this.addressList.length > 0) {
            this.defaultSelectAddressId = this.addressList[0].addressId;
            this.senderAddress = this.addressList[0].addressItem;
          }
        }
      });
    },
    // 进入发货信息编辑页面
    onEnterAddress() {
      this.$router.push({
        name: "ShippingAddress",
        params: { procureOrderSn: this.orderSn }
      });
      this.sendVisible = false;
    },
    // 选择发货地址信息
    onSureAddress() {
      if (this.defaultSelectAddressId === "") {
        this.$message.info("请选择一个发货地址");
      } else {
        const index = this.addressList.findIndex(
          item => item.addressId === this.defaultSelectAddressId
        );
        this.senderAddress = this.addressList[index].addressItem;
        this.sendVisible = false;
      }
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "producer").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 不通过
    onReject() {
      if (!this.approvalMemo) {
        this.$message.info("请输入订单审核意见");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: false,
        approvalMemo: this.approvalMemo
      };
      orderExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.push({ name: "ChangeOrderExamineListFactory" });
        }
      });
    },
    // 通过
    onPass() {
      if (!this.defaultSelectAddressId) {
        this.$message.info("请选择商家收发货信息");
        return;
      }
      const addressItem = this.addressList.find(
        item => item.addressId === this.defaultSelectAddressId
      );
      this.passLoading = true;
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo,
        consigneeName: addressItem.senderName,
        consigneeTel: addressItem.senderTel,
        consigneeProvinceCode: addressItem.provinceCode,
        consigneeCityCode: addressItem.cityCode,
        consigneeCountyCode: addressItem.countyCode,
        consigneeDetailAddress: addressItem.detailAddress
      };
      orderExamine(params).then(resp => {
        this.passLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.push({ name: "ChangeOrderExamineListFactory" });
        }
      });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderSn) {
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailFactory",
          params: { orderSn: orderSn, type: "detail" }
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.radio__item {
  margin-bottom: 10px;
}

.address__container {
  margin-top: 20px;
}

.commodity__table {
  margin-top: 20px;
}

.high__bottom {
  margin-bottom: 150px;
}

.low__bottom {
  margin-bottom: 30px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 20px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.operation {
  display: flex;
  justify-content: flex-end;
}

.log__table {
  margin-top: 20px;
}

.status--orange {
  color: rgba(255, 134, 0, 1);
}

.pic__container {
  display: flex;
}

.pic__container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}
</style>
